import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import Seo from '../../components/Seo'
import {
  Box,
  Heading,
  Container,
  Flex
} from '@chakra-ui/react'
import SummerBanner from '../../components/MountainBanner/SummerBanner/SummerBanner'
import { PrismicRichText } from "@prismicio/react"
import SliceZone from "../../components/SliceZone/SliceZone"


const StayPage = ({ data, location }) => {
  const document = data.allPrismicDiscoverPage.edges[0].node.data;
  const pageLocation = location.pathname;

  return (
    <Layout pageLocation={pageLocation}>
      <Seo title={document.page_title.text} />
      <SummerBanner 
        headline={document.page_title.text}
      />
      <Container maxW="container.xl" my="16" >
          <Box as="section" mb="12"><PrismicRichText field={document.page_content.richText}  /></Box>
          </Container>
          <SliceZone sliceZone={document.body} />

      

    </Layout>
  )
}

export const query = graphql`
{
  allPrismicDiscoverPage(filter: {uid: {eq: "summer"}}) {
    edges {
      node {
        _previewable
        data {
          page_title {
            text
          }
          page_content {
            richText
          }
          body {
            ... on PrismicDiscoverPageDataBodyGridType1 {
              id
              slice_type
              primary {
                landscape_image_label
                landscape_image_color
                landscape_image_tag
                landscape_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 1.9, layout: FULL_WIDTH)
                    }
                  }
                }
                landscape_image_link {
                  url
                }
                portrait_image_label
                portrait_image_color
                portrait_image_tag
                portrait_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
                square_image_1_label
                square_image_1_color
                square_image_1_tag
                square_image_1_link {
                  url
                }
                portrait_image_link {
                  url
                }
                square_image_1 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                square_image_2 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                square_image_2_label
                square_image_2_color
                square_image_2_tag
                square_image_2_link {
                  url
                }
                square_image_3 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                square_image_3_label
                square_image_3_color
                square_image_3_tag
                square_image_3_link {
                  url
                }
                square_image_4 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                square_image_4_label
                square_image_4_color
                square_image_4_tag
                square_image_4_link {
                  url
                }
                square_image_1_content {
                  richText
                }
                square_image_2_content {
                  richText
                }
                square_image_3_content {
                  richText
                }
                square_image_4_content {
                  richText
                }
                portrait_image_content {
                  richText
                }
                landscape_image_content {
                  richText
                }
                landscape_image_link_label
                portrait_image_link_label
                square_image_1_link_label
                square_image_2_link_label
                square_image_3_link_label
                square_image_4_link_label
              }
            }
            ... on PrismicDiscoverPageDataBodyGridType2 {
              id
              slice_type
              primary {
                square_image_4_link {
                  url
                }
                square_image_4_label
                square_image_4_color
                square_image_4_tag
                square_image_4 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                  alt
                }
                square_image_3_link {
                  url
                }
                square_image_3_label
                square_image_3_color
                square_image_3_tag
                square_image_3 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                  alt
                }
                square_image_2_link {
                  url
                }
                square_image_2_label
                square_image_2_color
                square_image_2_tag
                square_image_2 {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                square_image_1_label
                square_image_1_color
                square_image_1_tag
                square_image_1_link {
                  url
                }
                square_image_1 {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                square_image_1_content {
                  richText
                }
                square_image_2_content {
                  richText
                }
                square_image_3_content {
                  richText
                }
                square_image_4_content {
                  richText
                }
                square_image_1_link_label
                square_image_2_link_label
                square_image_3_link_label
                square_image_4_link_label
              }
            }
            ... on PrismicDiscoverPageDataBodyFullImageSection {
              id
              slice_type
              primary {
                button_label
                button_color
                text_content {
                  richText
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
                headline {
                  text
                }
                button_link {
                  url
                  target
                }
              }
            }
            ... on PrismicDiscoverPageDataBodyAdvertisement {
              id
              slice_type
              primary {
                mobile_ad_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  alt
                }
                desktop_ad_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
                ad_link {
                  url
                  target
                }
              }
            }
          }
        }
      }
    }
  }
}
`


export default StayPage
